import {
  HTMLAttributes,
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import joinClassNames from "classnames";

import useFieldData from "hooks/use-field-data";

import {
  focusHandler,
  inputHandler,
  keyDownHandler,
  pasteHandler,
} from "./duck/operations";
import { CodeInputTypes } from "./duck/types";
import { PREPARES } from "./duck/constants";

import classes from "./styles/classes.module.scss";

interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  length?: number;
  value?: string;
  type?: CodeInputTypes;
  onChange?: (value: string) => void;
  onFilled?: (value: string) => void;
  inputClassName?: string;
  wrapperProps?: Omit<HTMLAttributes<HTMLDivElement>, "className">;
  classNames?: Partial<{
    input: string;
  }>;
}

const CodeInput = forwardRef<HTMLInputElement[], Props>((props, ref) => {
  const {
    length = 6,
    value = [],
    autoFocus = false,
    type = "number",
    onChange,
    onFilled,
    classNames = {},
    wrapperProps,
    className,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name,
    ...restProps
  } = useFieldData(props);

  const inputsRef = useRef<HTMLInputElement[]>([]);

  const context = {
    onChange,
    inputs: inputsRef.current,
    prepareValue: PREPARES[type],
    onFilled,
  };

  useImperativeHandle(ref, () => inputsRef.current);

  return (
    <div
      className={joinClassNames(classes.codeInputWrapper, className)}
      {...wrapperProps}
    >
      {Array.from({ length }).map((e, index) => (
        <input
          className={joinClassNames(
            classes.blank,
            classes.codeInput,
            classNames.input,
          )}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onAnimationEnd={event => event.stopPropagation()}
          onPaste={event => pasteHandler(event, index, context)}
          maxLength={1}
          ref={elem => {
            inputsRef.current[index] = elem!;
          }}
          autoComplete="one-time-code"
          onFocus={event => focusHandler(event, index, context)}
          onInput={event => inputHandler(event, index, context)}
          autoFocus={!index && autoFocus}
          value={value[index]?.trim() || ""}
          type={type === "password" ? "password" : type}
          onKeyDown={event => keyDownHandler(event, index, context)}
          placeholder="_"
          {...restProps}
        />
      ))}
    </div>
  );
});

export default CodeInput;
