import { useRef } from "react";
import {
  useError,
  useFormContext,
  useFormState,
  useValidate,
} from "recalculate-form";

import { CodeInput } from "basics/input";

import classes from "./styles/classes.module.scss";

interface Props {
  onFilled: (value: string) => void;
}

const VerifyCodeInput = ({ onFilled }: Props) => {
  const codeInputRef = useRef<HTMLInputElement[]>([]);
  const { errors } = useError();
  const { isSubmitting } = useFormState();
  const form = useFormContext();
  const errorMessage = errors.code || errors.resend;

  useValidate<{ code: string }>(
    ({ code }, errors: { code?: string | null }) => {
      if (code.length) {
        errors.code = null;
      }

      return errors;
    },
    [],
  );

  return (
    <>
      <CodeInput
        className={classes.codeInput}
        ref={codeInputRef}
        name="code"
        inputMode="numeric"
        autoFocus
        readOnly={isSubmitting}
        onFilled={onFilled}
        classNames={{ input: classes.codeInputItem }}
        wrapperProps={{
          "aria-invalid": Boolean(errors.code),
          onAnimationEnd: () => {
            if (errors.code) {
              form.commit([{ path: "code", value: [] }]);
              codeInputRef.current[0]?.focus();
            }
          },
        }}
      />
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
    </>
  );
};

export default VerifyCodeInput;
