import { useError, useFormState, useWatch } from "recalculate-form";

import Button from "basics/button";

import classes from "./styles/classes.module.scss";

const ContinueButton = () => {
  const email = useWatch("email");
  const { isSubmitting } = useFormState();
  const { errors } = useError();

  return (
    <Button
      className={classes.button}
      themeName="primary"
      type="submit"
      isBlock
      isLoading={isSubmitting}
      disabled={!email || Boolean(errors.email) || isSubmitting}
    >
      Continue
    </Button>
  );
};

export default ContinueButton;
