import { useError, useFormState, useWatch } from "recalculate-form";

import Button from "basics/button";

import classes from "./styles/classes.module.scss";

const VerifyButton = () => {
  const { isSubmitting } = useFormState();
  const code = useWatch<string[]>("code");
  const { errors } = useError();

  return (
    <Button
      className={classes.button}
      themeName="primary"
      isLoading={isSubmitting}
      type="submit"
      disabled={Boolean(errors.code) || code.length < 6}
      isBlock
    >
      Verify
    </Button>
  );
};

export default VerifyButton;
