import { useRef } from "react";
import { FormProvider, useForm, useValidate } from "recalculate-form";

import PointingUpEmoji from "emojis/pointing-up.svg?react";
import EnvelopEmoji from "emojis/envelop.svg?react";

import accountAPI from "api/account";

import Input from "basics/input";

import { openAlert } from "components/dialog";

import ContinueButton from "./components/continue-button";

import { getErrorType, getFieldError } from "../../duck/selectors";

import signinModalClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
}

const SendEmail = ({ nextStep }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useForm({
    defaultValues: { email: accountAPI.codeTimes.value?.email || "" },
  });

  const submitHandler = async ({
    values: { email },
  }: {
    values: { email: string };
  }) => {
    form.resetErrors();
    try {
      if (
        email === accountAPI.codeTimes.value?.email &&
        Date.now() - accountAPI.codeTimes.value.time <
          accountAPI.RESEND_CODE_DELAY
      ) {
        accountAPI.codeTimes.change({ isCanceled: false });
      } else {
        await accountAPI.sendAuthCode(email);
      }

      nextStep();
    } catch (error) {
      if (getErrorType(error)) {
        form.setErrors({ email: getFieldError(error as Error, "email") });
        inputRef.current?.focus();
      } else {
        openAlert({
          message: "An error occurred while sending the email",
        });
      }
    }
  };

  useValidate(() => null, [], form);

  return (
    <FormProvider form={form}>
      <form
        className={signinModalClasses.contentForm}
        onSubmit={form.handleSubmit(submitHandler)}
      >
        <div>
          <div className={classes.titleWrapper}>
            <h2 className={signinModalClasses.gradientTitle}>
              Enter your email
            </h2>
            <EnvelopEmoji />
          </div>
          <p className={signinModalClasses.description}>
            Verify with your email address to maximize the benefits of Coindisco
          </p>
        </div>
        <Input
          ref={inputRef}
          autoFocus
          isBlock
          name="email"
          size="md"
          placeholder="Email"
        />
        <div className={classes.banner}>
          <PointingUpEmoji />
          If you don't have an account yet, it will be created automatically
        </div>
        <ContinueButton />
      </form>
    </FormProvider>
  );
};

export default SendEmail;
